import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				OceanDream Charters
			</title>
			<meta name={"description"} content={"Відкрийте для себе найкращий досвід оренди яхт з OceanDream Charters. Плавання на розкішних яхтах, дослідження захоплюючих місць та створення незабутніх спогадів.\n"} />
			<meta property={"og:title"} content={"OceanDream Charters"} />
			<meta property={"og:description"} content={"Відкрийте для себе найкращий досвід оренди яхт з OceanDream Charters. Плавання на розкішних яхтах, дослідження захоплюючих місць та створення незабутніх спогадів.\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link1" />
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Components.Marquee />
		<Section
			padding="140px 0 140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(120deg,rgba(0,0,0,.2) 0%,rgba(16, 1, 1, 0.73) 100%),--color-dark url(https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-1%20%281%29.jpg?v=2024-08-12T08:11:48.183Z) center/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Text margin="0px 0px 2rem 0px" font="--lead" text-transform="uppercase" text-align="center">
				Ласкаво просимо до OceanDream Charters: ваш портал до неперевершених морських пригод
			</Text>
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 4rem 0"
					text-align="center"
					letter-spacing="20px"
					text-transform="uppercase"
					font="--headline3"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					Відкрийте для себе найкращий досвід оренди яхт з OceanDream Charters. Плавання на розкішних яхтах, дослідження захоплюючих місць та створення незабутніх спогадів.{"\n"}
				</Text>
				<Text margin="0px" text-align="right">
					Уявіть собі свободу відкритого моря, легке коливання хвиль під вами та нескінченний горизонт, що розкривається перед вашими очима. У OceanDream Charters ми втілюємо цю мрію в реальність, пропонуючи надзвичайний досвід, який поєднує розкіш, пригоду та відпочинок. Наш флот преміальних яхт готовий до вашого розпорядження, щоб вирушити в подорож до найкрасивіших і віддалених куточків світу. Чи ви шукаєте спокійний відпочинок, захоплюючу подорож або незабутнє святкування, OceanDream Charters стане вашим надійним партнером на воді.
				</Text>
			</Box>
			<Box
				display="flex"
				margin="0 -16px -16px -16px"
				flex-wrap="no-wrap"
				width="100%"
				justify-content="space-between"
				flex-direction="column"
			>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex" />
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-10">
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="--color-primary"
				font="normal 500 20px/1.5 --fontFamily-sansVerdana"
				border-color="--color-primary"
			>
				НАША МІСІЯ
			</Text>
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px" width="50%" lg-width="100%">
					<Text margin="0 0px 0px 0px" font="normal 600 42px/1.2 --fontFamily-serifGeorgia" color="#050c32" sm-font="normal 600 30px/1.2 --fontFamily-serifGeorgia">
						Плавання на розкішних яхтах, дослідження захоплюючих місць та створення незабутніх спогадів.{"\n"}
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					margin="0px 0px 0px 10%"
					width="40%"
					display="flex"
					justify-content="space-around"
					lg-margin="50px 0px 0px 0"
					lg-width="100%"
					lg-justify-content="flex-start"
					sm-flex-direction="column"
					sm-align-items="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						text-align="center"
						lg-margin="0px 45px 0px 0px"
						sm-margin="0px 0 30px 0px"
					>
						<Text
							margin="0px 0px 0px 0px"
							font="normal 600 72px/1.2 --fontFamily-serifGeorgia"
							color="--color-primary"
							border-color="--color-primary"
							sm-text-align="left"
						>
							20+
						</Text>
						<Text margin="15px 0px 0px 0px" color="#050c32" font="400 20px --fontFamily-sansTrebuchet">
							яхт
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" text-align="center">
						<Text
							margin="0px 0px 0px 0px"
							font="normal 600 72px/1.2 --fontFamily-serifGeorgia"
							color="--primary"
							border-color="--color-primary"
							sm-text-align="left"
						>
							7+
						</Text>
						<Text margin="15px 0px 0px 0px" color="#050c32" font="400 20px --fontFamily-sansTrebuchet">
							років досвіду
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="row"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="center"
				justify-content="center"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/66b9c205e778710018d287fe/images/2-1.jpg?v=2024-08-12T08:11:48.138Z"
						object-fit="contain"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-1.jpg?v=2024-08-12T08%3A11%3A48.138Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-1.jpg?v=2024-08-12T08%3A11%3A48.138Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-1.jpg?v=2024-08-12T08%3A11%3A48.138Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-1.jpg?v=2024-08-12T08%3A11%3A48.138Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-1.jpg?v=2024-08-12T08%3A11%3A48.138Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-1.jpg?v=2024-08-12T08%3A11%3A48.138Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-1.jpg?v=2024-08-12T08%3A11%3A48.138Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline3"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Виберіть OceanDream Charters і вирушайте в подорож, де кожна деталь створена з турботою, а кожна мить — це спогад, що чекає на створення.
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Маршрути, які ми пропонуємо, такі ж різноманітні, як і захоплюючі. Від кришталево чистих вод Карибського моря до історичних узбережжів Середземномор’я, OceanDream Charters відкриває для вас найкрасивіші морські пейзажі світу. Досліджуйте яскравий морський світ Великого Бар'єрного рифу, насолоджуйтесь сонцем на чистих пляжах Мальдів або плавайте через захоплюючі фьорди Норвегії. З OceanDream Charters світ — це ваша устриця.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			md-padding="80px 0 80px 0"
			background="--color-darkL1"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Text margin="0px 0px 15px 0px" color="--light" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center">
				Чому обирають нас
			</Text>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="normal 300 22px/1.5 --fontFamily-sansHelvetica" text-align="center">
				Завдяки суднам, які ідеально підходять як для інтимних зустрічей, так і для великих груп, кожен може насолодитися враженнями від плавання.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Розкішний флот:{" "}
								<br />
							</Strong>
							Наші яхти ретельно обслуговуються та оснащені найсучаснішими зручностями, щоб забезпечити комфортний і розкішний досвід на морі.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Професійний екіпаж:{" "}
								<br />
							</Strong>
							Наші професійні члени екіпажу високо кваліфіковані, ввічливі та віддані своїй справі, щоб зробити вашу подорож максимально приємною.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong>
								Індивідуальні маршрути:{" "}
								<br />
							</Strong>
							Ми пропонуємо індивідуальні маршрути, створені з урахуванням ваших інтересів, від дослідження прихованих бухт до обіду в ексклюзивних ресторанах на березі моря.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			/>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Text
					font="--headline3"
					lg-text-align="center"
					margin="16px 0px 24px 0px"
					color="--grey"
					md-text-align="left"
				>
					OceanDream Charters гарантує досконалий сервіс і пропонує вибір яхт, розроблених для вашого комфорту та безпеки.
				</Text>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://uploads.quarkly.io/66b9c205e778710018d287fe/images/2-3.jpg?v=2024-08-12T08:11:48.155Z"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
						srcSet="https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-3.jpg?v=2024-08-12T08%3A11%3A48.155Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-3.jpg?v=2024-08-12T08%3A11%3A48.155Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-3.jpg?v=2024-08-12T08%3A11%3A48.155Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-3.jpg?v=2024-08-12T08%3A11%3A48.155Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-3.jpg?v=2024-08-12T08%3A11%3A48.155Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-3.jpg?v=2024-08-12T08%3A11%3A48.155Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-3.jpg?v=2024-08-12T08%3A11%3A48.155Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://uploads.quarkly.io/66b9c205e778710018d287fe/images/2-4.jpg?v=2024-08-12T08:11:48.146Z"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-4.jpg?v=2024-08-12T08%3A11%3A48.146Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-4.jpg?v=2024-08-12T08%3A11%3A48.146Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-4.jpg?v=2024-08-12T08%3A11%3A48.146Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-4.jpg?v=2024-08-12T08%3A11%3A48.146Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-4.jpg?v=2024-08-12T08%3A11%3A48.146Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-4.jpg?v=2024-08-12T08%3A11%3A48.146Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/2-4.jpg?v=2024-08-12T08%3A11%3A48.146Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Ми зосереджені на наданні індивідуальних вражень, які задовольнять як випадкових дослідників, так і завзятих мореплавців.
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/66b9c205e778710018d287fe/images/1-3.jpg?v=2024-08-12T08:11:48.130Z"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						srcSet="https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/1-3.jpg?v=2024-08-12T08%3A11%3A48.130Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/1-3.jpg?v=2024-08-12T08%3A11%3A48.130Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/1-3.jpg?v=2024-08-12T08%3A11%3A48.130Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/1-3.jpg?v=2024-08-12T08%3A11%3A48.130Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/1-3.jpg?v=2024-08-12T08%3A11%3A48.130Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/1-3.jpg?v=2024-08-12T08%3A11%3A48.130Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66b9c205e778710018d287fe/images/1-3.jpg?v=2024-08-12T08%3A11%3A48.130Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Вибирайте з нашого різноманітного флоту та подбайте про те, щоб ваша подорож була спокійною, мальовничою та справді вашою.
				</Text>
			</Box>
		</Section>
		<Components.Marquee />
		<Components.Footer1>
			<Override slot="box3" />
		</Components.Footer1>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});